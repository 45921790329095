import { type FC } from 'react';
import { type FieldErrors, type UseFormRegisterReturn } from 'react-hook-form';
import FormLabel from './FormLable.tsx';

type FormInputProps = {
  label: string;
  attribute: string;
  register: UseFormRegisterReturn<string>;
  errors: FieldErrors<any>;
  required: boolean;
};

const FormInput: FC<FormInputProps> = (props) => (
  <div className="mt-5 mb-5">
    <FormLabel
      label={props.label}
      attribute={props.attribute}
      required={props.required}
    />
    <input
      type="text"
      id={props.attribute}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      {...props.register}
      required={props.required}
    />
    {props.errors[props.attribute] && (
      <div className="error_messages">{`${props.label}を入力してください`}</div>
    )}
  </div>
);

export default FormInput;
